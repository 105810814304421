<app-navbar></app-navbar>
<div class="org-nav" *ngIf="organization">
    <div class="container d-flex flex-column">
        <div class="my-auto d-flex align-items-center pl-1">
            <app-avatar [data]="organization.name" size="45" [circle]="true"></app-avatar>
            <div class="org-name ml-3">
                <span>{{organization.name}}</span>
                <small class="text-muted">{{'organization' | i18n}}</small>
            </div>
            <div class="ml-auto card border-danger text-danger bg-transparent" *ngIf="!organization.enabled">
                <div class="card-body py-2">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{'organizationIsDisabled' | i18n}}
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs" *ngIf="organization.isAdmin">
            <li class="nav-item">
                <a class="nav-link" routerLink="vault" routerLinkActive="active">
                    <i class="fa fa-lock"></i>
                    {{'vault' | i18n}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="manage" routerLinkActive="active">
                    <i class="fa fa-sliders"></i>
                    {{'manage' | i18n}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="tools" routerLinkActive="active">
                    <i class="fa fa-wrench"></i>
                    {{'tools' | i18n}}
                </a>
            </li>
            <li class="nav-item" *ngIf="organization.isOwner">
                <a class="nav-link" routerLink="settings" routerLinkActive="active">
                    <i class="fa fa-cogs"></i>
                    {{'settings' | i18n}}
                </a>
            </li>
        </ul>
    </div>
</div>
<router-outlet></router-outlet>
<app-footer></app-footer>

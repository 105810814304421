<app-callout type="warning">{{'loggedOutWarning' | i18n}}</app-callout>
<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="currentMasterPassword">{{'currentMasterPass' | i18n}}</label>
                <input id="currentMasterPassword" type="password" name="MasterPasswordHash" class="form-control" [(ngModel)]="currentMasterPassword"
                    required appInputVerbatim>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="newMasterPassword">{{'newMasterPass' | i18n}}</label>
                <input id="newMasterPassword" type="password" name="NewMasterPasswordHash" class="form-control" [(ngModel)]="newMasterPassword"
                    required appInputVerbatim autocomplete="new-password">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label for="confirmNewMasterPassword">{{'confirmNewMasterPass' | i18n}}</label>
                <input id="confirmNewMasterPassword" type="password" name="ConfirmNewMasterPasswordHash" class="form-control" [(ngModel)]="confirmNewMasterPassword"
                    required appInputVerbatim autocomplete="new-password">
            </div>
        </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
        <span>{{'changeMasterPassword' | i18n}}</span>
    </button>
</form>

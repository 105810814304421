<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" class="container" ngNativeValidate>
    <div class="row justify-content-md-center mt-5">
        <div class="col-5">
            <p class="lead text-center mb-4">{{'createAccount' | i18n}}</p>
            <div class="card d-block">
                <div class="card-body">
                    <app-callout title="{{'createOrganizationStep1' | i18n}}" type="info" icon="fa-thumb-tack" *ngIf="showCreateOrgMessage">
                        {{'createOrganizationCreatePersonalAccount' | i18n}}
                    </app-callout>
                    <div class="form-group">
                        <label for="email">{{'emailAddress' | i18n}}</label>
                        <input id="email" class="form-control" type="text" name="Email" [(ngModel)]="email" required [appAutofocus]="email === ''"
                            inputmode="email" appInputVerbatim="false">
                        <small class="form-text text-muted">{{'emailAddressDesc' | i18n}}</small>
                    </div>
                    <div class="form-group">
                        <label for="name">{{'yourName' | i18n}}</label>
                        <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="name" [appAutofocus]="email !== ''">
                        <small class="form-text text-muted">{{'yourNameDesc' | i18n}}</small>
                    </div>
                    <div class="form-group">
                        <label for="masterPassword">{{'masterPass' | i18n}}</label>
                        <div class="d-flex">
                            <input id="masterPassword" type="{{showPassword ? 'text' : 'password'}}" name="MasterPassword" class="text-monospace form-control"
                                [(ngModel)]="masterPassword" required appInputVerbatim>
                            <button type="button" class="ml-1 btn btn-link" title="{{'toggleVisibility' | i18n}}" (click)="togglePassword(false)">
                                <i class="fa fa-lg" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                            </button>
                        </div>
                        <small class="form-text text-muted">{{'masterPassDesc' | i18n}}</small>
                    </div>
                    <div class="form-group">
                        <label for="masterPasswordRetype">{{'reTypeMasterPass' | i18n}}</label>
                        <div class="d-flex">
                            <input id="masterPasswordRetype" type="{{showPassword ? 'text' : 'password'}}" name="MasterPasswordRetype" class="text-monospace form-control"
                                [(ngModel)]="confirmMasterPassword" required appInputVerbatim>
                            <button type="button" class="ml-1 btn btn-link" title="{{'toggleVisibility' | i18n}}" (click)="togglePassword(true)">
                                <i class="fa fa-lg" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="hint">{{'masterPassHint' | i18n}}</label>
                        <input id="hint" class="form-control" type="text" name="Hint" [(ngModel)]="hint">
                        <small class="form-text text-muted">{{'masterPassHintDesc' | i18n}}</small>
                    </div>
                    <hr>
                    <div class="d-flex mb-2">
                        <button type="submit" class="btn btn-primary btn-block btn-submit" [disabled]="form.loading">
                            <span>{{'submit' | i18n}}</span>
                            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                        </button>
                        <a routerLink="/" class="btn btn-outline-secondary btn-block ml-2 mt-0">
                            {{'cancel' | i18n}}
                        </a>
                    </div>
                    <small class="text-muted" *ngIf="showTerms">
                        {{'submitAgreePolicies' | i18n}}
                        <a href="https://bitwarden.com/terms/" target="_blank" rel="noopener">{{'termsOfService' | i18n}}</a>,
                        <a href="https://bitwarden.com/privacy/" target="_blank" rel="noopener">{{'privacyPolicy' | i18n}}</a>
                    </small>
                </div>
            </div>
        </div>
    </div>
</form>

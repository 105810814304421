<ng-container *ngIf="ciphers">
    <table class="table table-hover table-list table-ciphers" *ngIf="ciphers.length > 0">
        <tbody>
            <tr *ngFor="let c of ciphers">
                <td (click)="checkCipher(c)" class="table-list-checkbox" *ngIf="!organization">
                    <input type="checkbox" [(ngModel)]="c.checked">
                </td>
                <td (click)="checkCipher(c)" class="table-list-icon">
                    <app-vault-icon [cipher]="c"></app-vault-icon>
                </td>
                <td (click)="checkCipher(c)" class="reduced-lh wrap">
                    <a href="#" appStopClick appStopProp (click)="selectCipher(c)" title="{{'editItem' | i18n}}">{{c.name}}</a>
                    <i class="fa fa-share-alt" appStopProp *ngIf="!organization && c.organizationId" title="{{'shared' | i18n}}"></i>
                    <i class="fa fa-paperclip" appStopProp *ngIf="c.hasAttachments" title="{{'attachments' | i18n}}"></i>
                    <br>
                    <small appStopProp>{{c.subTitle}}</small>
                </td>
                <td class="table-list-options">
                    <div class="dropdown" appListDropdown>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="fa fa-cog fa-lg"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" appStopClick *ngIf="c.type === cipherType.Login" (click)="copy(c.login.password, 'password', 'password')">
                                <i class="fa fa-fw fa-clipboard"></i>
                                {{'copyPassword' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick (click)="attachments(c)">
                                <i class="fa fa-fw fa-paperclip"></i>
                                {{'attachments' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick *ngIf="!organization && !c.organizationId" (click)="share(c)">
                                <i class="fa fa-fw fa-share-alt"></i>
                                {{'share' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick *ngIf="c.organizationId" (click)="collections(c)">
                                <i class="fa fa-fw fa-cubes"></i>
                                {{'collections' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick *ngIf="c.organizationId && accessEvents" (click)="events(c)">
                                <i class="fa fa-fw fa-file-text-o"></i>
                                {{'eventLogs' | i18n}}
                            </a>
                            <a class="dropdown-item text-danger" href="#" appStopClick (click)="delete(c)">
                                <i class="fa fa-fw fa-trash-o"></i>
                                {{'delete' | i18n}}
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="no-items" *ngIf="ciphers.length === 0">
        <i class="fa fa-spinner fa-spin text-muted" *ngIf="!loaded" title="{{'loading' | i18n}}"></i>
        <ng-container *ngIf="loaded">
            <p>{{'noItemsInList' | i18n}}</p>
            <button (click)="addCipher()" class="btn btn-outline-primary" *ngIf="showAddNew">
                <i class="fa fa-plus fa-fw"></i>{{'addItem' | i18n}}</button>
        </ng-container>
    </div>
</ng-container>

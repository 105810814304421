<div class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">
                    {{'twoStepLogin' | i18n}}
                    <small>FIDO U2F</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" attr.aria-label="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <app-two-factor-verify [organizationId]="organizationId" [type]="type" (onAuthed)="auth($event)" *ngIf="!authed">
            </app-two-factor-verify>
            <form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate *ngIf="authed">
                <div class="modal-body">
                    <app-callout type="success" title="{{'enabled' | i18n}}" icon="fa-check-circle" *ngIf="enabled">
                        {{'twoStepLoginProviderEnabled' | i18n}}
                    </app-callout>
                    <app-callout type="warning">
                        <p>{{'twoFactorU2fWarning' | i18n}}</p>
                        <ul class="mb-0">
                            <li>{{'twoFactorU2fSupportWeb' | i18n}}</li>
                        </ul>
                    </app-callout>
                    <ng-container *ngIf="!enabled">
                        <img src="images/4.png" class="float-right ml-5" alt="">
                        <p>{{'twoFactorU2fAdd' | i18n}}:</p>
                        <ol>
                            <li>{{'twoFactorU2fPlugIn' | i18n}}</li>
                            <li>{{'twoFactorU2fTouchButton' | i18n}}</li>
                        </ol>
                        <hr>
                        <div class="text-center">
                            <ng-container *ngIf="u2fListening">
                                <p>
                                    <i class="fa fa-spinner fa-spin fa-2x text-muted"></i>
                                </p>
                                {{'twoFactorU2fWaiting' | i18n}}...
                            </ng-container>
                            <ng-container *ngIf="u2fResponse">
                                <p>
                                    <i class="fa fa-check-circle fa-2x text-success"></i>
                                </p>
                                {{'twoFactorU2fClickEnable' | i18n}}
                            </ng-container>
                            <ng-container *ngIf="u2fError">
                                <p>
                                    <i class="fa fa-warning fa-2x text-danger"></i>
                                </p>
                                {{'twoFactorU2fProblemReading' | i18n}}
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" [disabled]="form.loading || (!enabled && !u2fResponse)">
                        <i class="fa fa-spinner fa-spin" *ngIf="form.loading" title="{{'loading' | i18n}}"></i>
                        <ng-container *ngIf="!form.loading">
                            <span *ngIf="!enabled">{{'enable' | i18n}}</span>
                            <span *ngIf="enabled">{{'disable' | i18n}}</span>
                        </ng-container>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close' | i18n}}</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="card vault-filters">
    <div class="card-header">
        {{'filters' | i18n}}
    </div>
    <div class="card-body">
        <input type="search" placeholder="{{searchPlaceholder || ('searchVault' | i18n)}}" id="search" class="form-control" [(ngModel)]="searchText"
            (input)="searchTextChanged()" appAutofocus>
        <ul class="fa-ul card-ul">
            <li [ngClass]="{active: selectedAll}">
                <a href="#" appStopClick (click)="selectAll()">
                    <i class="fa-li fa fa-fw fa-th"></i>{{'allItems' | i18n}}
                </a>
            </li>
            <li [ngClass]="{active: selectedFavorites}" *ngIf="showFavorites">
                <a href="#" appStopClick (click)="selectFavorites()">
                    <i class="fa-li fa fa-fw fa-star"></i>{{'favorites' | i18n}}
                </a>
            </li>
        </ul>
        <h3>{{'types' | i18n}}</h3>
        <ul class="fa-ul card-ul">
            <li [ngClass]="{active: selectedType === cipherType.Login}">
                <a href="#" appStopClick (click)="selectType(cipherType.Login)">
                    <i class="fa-li fa fa-fw fa-globe"></i>{{'typeLogin' | i18n}}
                </a>
            </li>
            <li [ngClass]="{active: selectedType === cipherType.Card}">
                <a href="#" appStopClick (click)="selectType(cipherType.Card)">
                    <i class="fa-li fa fa-fw fa-credit-card"></i>{{'typeCard' | i18n}}
                </a>
            </li>
            <li [ngClass]="{active: selectedType === cipherType.Identity}">
                <a href="#" appStopClick (click)="selectType(cipherType.Identity)">
                    <i class="fa-li fa fa-fw fa-id-card-o"></i>{{'typeIdentity' | i18n}}
                </a>
            </li>
            <li [ngClass]="{active: selectedType === cipherType.SecureNote}">
                <a href="#" appStopClick (click)="selectType(cipherType.SecureNote)">
                    <i class="fa-li fa fa-fw fa-sticky-note-o"></i>{{'typeSecureNote' | i18n}}
                </a>
            </li>
        </ul>
        <p *ngIf="!loaded" class="text-muted">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
        </p>
        <ng-container *ngIf="loaded">
            <ng-container *ngIf="showFolders">
                <h3 class="d-flex">
                    {{'folders' | i18n}}
                    <a href="#" class="text-muted ml-auto" appStopClick (click)="addFolder()" title="{{'addFolder' | i18n}}">
                        <i class="fa fa-plus fa-fw"></i>
                    </a>
                </h3>
                <ul class="fa-ul card-ul carets">
                    <li *ngFor="let f of folders" class="d-flex" [ngClass]="{active: selectedFolder && f.id === selectedFolderId}">
                        <a href="#" appStopClick (click)="selectFolder(f)">
                            <i class="fa-li fa fa-caret-right"></i> {{f.name}}</a>
                        <a href="#" class="text-muted ml-auto show-active" appStopClick (click)="editFolder(f)" title="{{'editFolder' | i18n}}" *ngIf="f.id">
                            <i class="fa fa-pencil fa-fw"></i>
                        </a>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="showCollections && collections && collections.length">
                <h3>{{'collections' | i18n}}</h3>
                <ul class="fa-ul card-ul carets">
                    <li *ngFor="let c of collections" [ngClass]="{active: c.id === selectedCollectionId}">
                        <a href="#" appStopClick (click)="selectCollection(c)">
                            <i class="fa-li fa fa-caret-right"></i> {{c.name}}</a>
                    </li>
                </ul>
            </ng-container>
        </ng-container>
    </div>
</div>

<div *ngIf="loading">
    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}"></i>
</div>
<form *ngIf="profile && !loading" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="name">{{'name' | i18n}}</label>
                <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="profile.name">
            </div>
            <div class="form-group">
                <label for="email">{{'email' | i18n}}</label>
                <input id="email" class="form-control" type="text" name="Email" [(ngModel)]="profile.email" readonly>
            </div>
            <div class="form-group">
                <label for="masterPasswordHint">{{'masterPassHintLabel' | i18n}}</label>
                <input id="masterPasswordHint" class="form-control" type="text" name="MasterPasswordHint" [(ngModel)]="profile.masterPasswordHint">
            </div>
        </div>
        <div class="col-6">
            <app-avatar data="{{profile.name || profile.email}}" [email]="profile.email" dynamic="true" size="75" fontSize="35"></app-avatar>
        </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
        <span>{{'save' | i18n}}</span>
    </button>
</form>

<div class="modal fade">
    <div class="modal-dialog">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title">
                    {{'attachments' | i18n}}
                    <small *ngIf="cipher">{{cipher.name}}</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" attr.aria-label="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-hover table-list" *ngIf="cipher && cipher.hasAttachments">
                    <tbody>
                        <tr *ngFor="let a of cipher.attachments">
                            <td class="table-list-icon">
                                <i class="fa fa-fw fa-lg fa-file-o" *ngIf="!a.downloading"></i>
                                <i class="fa fa-spinner fa-lg fa-fw fa-spin" *ngIf="a.downloading"></i>
                            </td>
                            <td>
                                <a href="#" appStopClick (click)="download(a)">{{a.fileName}}</a>
                                <br>
                                <small>{{a.sizeName}}</small>
                            </td>
                            <td class="table-list-options">
                                <button class="btn btn-outline-danger" type="button" appStopClick title="{{'delete' | i18n}}" (click)="delete(a)" #deleteBtn
                                    [appApiAction]="deletePromises[a.id]" [disabled]="deleteBtn.loading">
                                    <i class="fa fa-trash-o fa-lg fa-fw" [hidden]="deleteBtn.loading"></i>
                                    <i class="fa fa-spinner fa-spin fa-lg fa-fw" [hidden]="!deleteBtn.loading" title="{{'loading' | i18n}}"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h3>{{'newAttachment' | i18n}}</h3>
                <label for="file" class="sr-only">{{'file' | i18n}}</label>
                <input type="file" id="file" class="form-control-file" name="file" required>
                <small class="form-text text-muted">{{'maxFileSize' | i18n}}</small>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" title="{{'close' | i18n}}">{{'close' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
